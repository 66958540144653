@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap');

.hidemobile{
    display: none;
}
.nextarrowslider{
    transform: scale(-1);
}

.nextarrowslider svg, .arrowslider svg{
    width:40px;
    cursor: pointer;
    transition: .15s fill ease-in;
    transition: .15s ease-in;
}
.nextarrowslider svg:hover, .arrowslider svg:hover{
    fill: #1C6EA4;
    transform: scale(1.1);
}

html{
    scroll-behavior:smooth;
}
body{
    margin: 0;
    padding: 0;
}
    *{font-family: 'Montserrat',sans-serif;}

a{
    text-decoration: none;
}
ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

    .images-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
    }

  .each-slide img{
      width:100%;
      padding: 0;
      margin: 0;
  }
  .each-slide{
      margin: 5vh 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
  }
  .slider{
      margin: 0 auto;
      width: 90vw;
  }

    /*--------HEADER--------*/

    img.background-img{
        text-align: center;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
    }

p, span{
    font-size: .9em;
    line-height: 1.6em;
}
.toplinks{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
    width: 30px;
    padding-top: 10px;
    opacity: .9;
}
.titlebackground{
    display: none;
}

.toplinks img{
    width: 40px;
}

.toplinks a:hover{
    opacity:.65;
}

.toplinks a:nth-child(2) img {
  margin-top:10px;
}

.container{
    text-align: center;
    padding: .8em 1.2em;
}

header{
    display: flex;
    justify-content: space-between;
}


.logolight{
    width: 45px;
    margin-left: -7px;
    margin-top: 7px;
}

.main-title{
    margin-top: 0px;
    font-size: 12px;
    
}
.main-title h1{
    font-size: 22px;
    padding: 0;
}

.main-titlef{
    color: rgb(255, 255, 255); 
}
.arrowfader{
    width: 50px;
    text-align: center;
    margin:  0 auto;
    opacity: .4;
    margin-top: 0px;
    display:none;

}


/*-------END OF HEADER-------*/

/*-------ABOUT---------------*/



.about-container{
    width: 100%;
}

strong{
    text-transform: uppercase;
    opacity: 1;
}

.aboutsubtitle{
    font-size: 16px;
    margin: 0;
    color: #07254F;
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 90px;
    
}

.aboutsection{
    margin-top: 50px;
    text-align: center;
}
.aboutsection-second ul{
    border:3px solid #07254F;
    width: 85vw;
    padding: 0;
    margin: 0 auto;
    border-radius: 20px;
    
}
.aboutsection-second{ /* -------LINE--------*/
    border-top: 3px solid #07254F;
    height: 460px;
    margin-bottom:-50px;
}
.aboutsection-second p{
    font-size: 20px;
    font-weight: 00;
    color: #07254F;
}
.aboutimage{
    width: 400px;
    border-radius: 10px;
}

.aboutsection-second ul li{
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 25px 0;
    font-size: 16px;
    color: #07254F;
    font-weight: 500;
    margin: 0 auto;

}
.aboutsection-second ul li:not(:first-child){
    border-top: 3px solid #07254F;
}

.abouttext{
    color: #07254F;
    background-color: white;
    width: 100%;
    font-weight: 500;
    opacity: .7;
    text-align: justify;
    font-size: 14px;
}
.abouttextnumul{
    font-size: 14px;
    text-align: left;
    
}
.abouttextnum{
    padding: 5px 0;
    font-weight: 500;
    opacity: .7;
    color: #07254F;
}


/*-------END OF ABOUT-------*/

/*-------GENERAL INFO-------*/

.generalinfo-container{
    background: none;
    text-align: center;
    margin-top: -20px;
    background-color: #f4f8ff;
}
.firstgeneral{
    background: rgb(7,37,79);
}
.papercandy img{
    width: 90vw;
    border-radius: 20px;
}

.papercandy{
    margin-top: 50px;
}
.papercandy h2{
    padding: 30px 0 10px 0;
    color:white;
}

.generalinfo-container ul{
    display: inline-block;
    text-align: center;
    color:white;

}
.generalinfo-container ul li img{
    width: 50px;
    padding-top: 10px;
    display: block;
    margin: 0 auto;
    
}
.generalinfo-container ul li:nth-child(2) img{
    width: 60px;
}
.generalinfo-container ul li{
    padding: 10px 0;
    height: 100px;
    font-size: 20px;
    font-weight: 400;
    color: #000;

}
.generalinfo-container p{
    width: 70vw;
    margin: 0 auto;
    padding-bottom: 20px;
    text-align:center;
    font-weight: 500;
    font-size: 16px;

}
.generalinfo-container h5{
    color: white;
    font-weight: 500;
    width: 90vw;
    margin: 20px auto;
    padding-bottom: 50px;
}

/*-------EQUIPMENT----------*/

.equipment-first-img{
    width: 300px;
}
.yy img {
    width: 75vw;
}
.yy:nth-child(3) img{
    width: 85vw;
}

.yy:nth-child(4) img{
    width:85vw;
}
.yy{
    margin: auto 0;
}
.equipment-first p{
text-align: justify;
width: 90vw;
font-size: 16px;
line-height: 1.5em;
opacity: .6;

}

/*------TABLE------*/

.tableshowbutton{
    border: none;
    font-family: 'Montserrat';
    cursor: pointer;
    background: rgb(6, 17, 46);
    color: white;
    width: 200px;
    height:70px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    outline:none;
  
}
.tableshowbutton:visited{
    border: none;

}

.hidetable{
    display: none;
}

.tablecontainer{ 
    font-family: 'Montserrat';
    font-size: 8px;
    text-align: center;
    padding:0 3px;
    
}
table.blueTable {
    border: 1px solid #000000;
    background-color: #07254F;
    width: 70vw;
    text-align:center;
    margin: 0 auto;
    margin-top: 20px;
    border-collapse: collapse;
  }

  table.blueTable td, table.blueTable th {
    border: 2px solid #FFFFFF;
    padding: 3px 2px;
  }

  table.blueTable tbody td {
      font-weight: 500;
    font-size: 13px;
    color: #ffffff;
  }
  table.blueTable tbody td:nth-child(1) {
    font-size: 13px;
    color: #000000;
  }
  table.blueTable td:nth-child(1) {
    background: #fff;
  }
  table.blueTable thead {
    background: #07254F;
   border-bottom: 2px solid #07254F;
  }
  table.blueTable thead th {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    border-left: 2px solid #07254F;
  }
  table.blueTable thead th:first-child {
    border-left: none;
  }
  
  table.blueTable tfoot td {
    font-size: 14px;
  }
  table.blueTable tfoot .links {
    text-align: right;
  }
  table.blueTable tfoot .links a{
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 5px;
  }



/*---------MEDIA--------- */
 
.mediatitle{
    padding-top: 50px;
    font-size: 18px;
    text-transform: uppercase;
    padding-bottom: 20px;
    width: 76%;
    margin: 0 auto;
    border-bottom: 3px solid #07254F;
}
.mediatext{
    text-align: justify;
width: 90vw;
font-size: 16px;
line-height: 1.5em;
opacity: .6;
padding:20px 0;
}
iframe{
    width: 90vw;
    height: 50vw;
    padding-bottom: 50px;
}
/*---------CLIENTS----------*/
.clients-container{
    background-color: #FFF;
}
.clients-container h1{
    
    font-size: 18px;
    text-transform: uppercase;
    padding: 20px 0;
width: 76%;
margin: 0 auto;
border-bottom: 5px solid #EFF1F4;
}
.clients-container div{
    padding: 1em 1em;
    
}
.clients-container div:nth-child(2) img{
    width: 300px;
   
}
.clients-container div:nth-child(3) img{
    width: 250px;
}
.clients-container div:nth-child(4) img{
    width: 100px;
}
.clients-container div:nth-child(5) img{
    width: 200px;
}
.clients-container div:nth-child(6) img{
    width: 200px;
    padding-bottom: 30px;
}
.clients-container div:nth-child(7) img{
    width:200px;
}




/*-------CONTACT----------*/
.contact-container{
    margin: 0 auto;
    border-top:5px solid #EFF1F4;
    
}

.contact-container img{
    width: 90px;
}
.firstcontactimg{
    width: 80px !important;
}
.contact-container div:nth-child(2){
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
}
.contact-container div{
    padding: 5px 0;
}
.contact-container{
    padding: 60px 0;

    width: 90vw;
font-size: 18px;
line-height: 1.5em;

}
.contact-container p, .contact-container span{
    opacity:.7;
}

/*---------FORM---------*/


.formheader{
    color: white;
    font-size: 22px;
}
.form{
    padding: .8em 1.2em;
    text-align: center;
    background-color: #b3b3b3;
    margin-top: 30px;
}
form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

label{
    padding: 30px 0 10px 0;
    font-size: 16px;
    opacity: .6;
}
input[type=text], textarea{
    outline:none;
    margin: 0 auto;
    padding: 10px 10px;
    width: 75vw;
    border: none;
    border-radius: 10px;
    box-sizing: border-box;
    resize:vertical;
    font-size: 17px;
    font-weight: 400;
}
#submit{
    margin: 30px auto;
    background-color: #07254F;
    font-size: 16px;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 12px 35px;
    
}
#submit:hover {
    background-color: #051a38;
}

.formerror{
    color: red;
    padding: 0;
    margin: 0 auto;
    text-align: center;
    display: none;
}


/*-------FOOTER--------*/

.footer-container{
    padding-top: 20px;
    background-color: #3d444a;
    color: white;
    width: 100%;
    clear: both;
    height: 220px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    text-align: center;
}
.innerfooter{
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding:0 10px;
    padding-top:20px;
}

.footerdiv1{
    padding-right: 150px;
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    text-align: center;
    
}
.footer-container div:nth-child(3){
    flex-direction: column;
}
.footerdiv2{
    display: flex;
    flex-direction: column;
}
.privacypolicy-link{
    padding: 10px 0 0 0;
    color:white;
   font-size: 15px;
   font-weight: 500;
   text-decoration: underline;
   text-align:center;
   
}
.footer-container p{
    padding-top: 0;
    color:white;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    
     
}
.privacypolicy-link:visited{
    color: white;
}
.privacypolicy-link:link{
    color: white;
}
.privacypolicy-link:active{
    color: white;
}

.footer-container div:nth-child(2) a img{
    width: 40px;
    
} 
.footer-container div:nth-child(2) a {
    padding: 5px 0 0 30px;
} 

.footersign img{
    width: 50px;
}
/*-------DATA-SCROLL---------*/

.aboutfade[data-scroll='out'] {
    opacity: 0.5;
    transform: translateY(150px);
}
 .aboutfade[data-scroll='in'] {
    opacity: 1;
    margin-top: -120px;
    transform: translateY(0px);
    transition: all 1.5s; 
    
}
.titlefade[data-scroll='out'] {
    opacity: 0.5;
    transform: translateY(-150px);
}
 .titlefade[data-scroll='in'] {
    opacity: 1;
   
    transform: translateY(0px);
    transition: all 1.5s; 
    
}


 .equipfade[data-scroll]{ 
   transition: all 1.5s;
}
.equipfade[data-scroll="out"]{
    opacity: 0.5; 
    transform: translateY(150px);
}

 .equipfade[data-scroll="in"]{
    opacity: 1;
    transform: translateY(0);
}



/*------GLIDER-------*/
.glider-contain{
    width: 85% !important;
    margin-top: 12%;
    margin-bottom: 5%;
}
.glider-next{
    right: -40px;
    top: 42%;
    
}
.glider-prev{
    left: -40px;
    top: 42%;

}


/*----SCREEN SIZING---------*/

/*--------750px--------*/

@media only screen and (min-width: 750px){
    
    
    .aboutsection-second ul{
        width: 70vw;
    }
    .aboutsection-second{
        width: 70vw;
        text-align: center;
        margin: 0 auto;
    }
    .aboutinfotop{
        width: 70vw;
        margin: 0 auto;
    }
    
    .abouttext{
        width: 70vw;
        margin: 0 auto;
        margin-top: 20px;
    }
    .papercandy img{
        width: 70vw;
    }
    .firstgeneral h5{
        width: 75vw;
        line-height: 1.5em;
        font-size: 15px;
    }
    table.blueTable{
        width: 80vw;
        height: 50vh;
    }
    table.blueTable tbody td {
      font-size: 15px;
    }
    table.blueTable tbody td:nth-child(1) {
      font-size: 15px;
    }
    table.blueTable thead th {
      font-size: 18px;
    }
    
    .yy img{
        width: 400px;
    }
    .equipment-first-img{
        width: 400px;
    }
    h4{
        font-size: 20px;
    }
    .footer-container div:first-child{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1px 1px;
        grid-template-areas: "." ".";
        text-align: center;
    }
    .privacypolicy-link{
        font-size: 18px;
    }
    .footer-container p{
        text-align: center;
        font-size: 18px;
    }
    .mediatext{
        width: 70vw;
        margin: 0 auto;
        padding: 50px 0;
        line-height: 1.5em;
    }
    iframe{
        width: 70vw;
        height: 45vh;
    }
    .aboutsubtitle{
        font-size: 19px;
    }
    .tableshowbutton{
        margin-bottom: 30px;
    }
    .equipment-first li p{
        margin: 0 auto;
        width: 70vw;
    
    }
    .logolight{
        width: 50px;
    }
}

/*--------1200px--------*/

@media only screen and (min-width: 1200px){
    
    .clientsimg1[data-scroll = 'out']{
        transform: translateX(-600px);
      
        opacity: 0.5;
    }
    .clientsimg2[data-scroll = 'out']{
        transform: translateX(-400px);
      
        opacity: 0.5;
    }
    .clientsimg3[data-scroll = 'out']{
        transform: translateX(-350px);
      
        opacity: 0.5;
    }
    .clientsimg4[data-scroll = 'out']{
        transform: translateX(-800px);
      
        opacity: 0.5;
    }
    .clientsimg5[data-scroll = 'out']{
        transform: translateX(-400px);
      
        opacity: 0.5;
    }
    .clientsimg6[data-scroll= 'out']{
        transform:translateX(-200px);
        opacity:0.5;
    }
    
    .clientsimg1[data-scroll = 'in'],.clientsimg2[data-scroll = 'in'],.clientsimg3[data-scroll = 'in'],.clientsimg4[data-scroll = 'in'],.clientsimg5[data-scroll = 'in'], .clientsimg6[data-scroll = 'in'] {
        transform: translateX(0);
        opacity: 1;
        transition: all 1.5s;
    }
 
    
    
    .papcandfade[data-scroll = 'out']{
        transform: translateY(0px);
        transition:  all 1s;
    }
    .papcandfade[data-scroll = 'in']{
        transform: translateY(-350px);
        transition: all 1s;
    }
    .main-title h1{
     display: none;    
    }
    .fixedbackground{
           background-color: rgb(255, 255, 255);
           z-index: 5;
           position: fixed;
           top: 0;
           height: 50px;
           width: 100%;
           display: flex;
           padding:15px 0;
       }
       .titlebackground{
           display: block;
        
           background-color: rgba(0, 0, 0, .3);
           position: absolute;
           z-index: 1;
           width: 600px;
           height: 400px;
           right: 8%;
           top: 30%;
           border-radius: 7px;
           text-align: center;
       }
       .subtitle{
        font-size: 17px;
        color: rgba(255, 255, 255, 0.8);
        z-index: 2;
        font-weight: 500;
        width: 70%;
        margin: 0 auto;
       }
       .titlebtns, .titlebtnf{
           border: none;
           outline: none;
           padding: 15px 30px;

       }
       .titlebtn{
           margin: 0 auto;
           margin-top: 50px;
           display: flex;
           justify-content: space-between;
           width: 55%;
       }
    
       .titlebtnf{
           border-radius: 7px;
           background: none;
           border: 2px solid white;
           color: white;
           font-weight: 500;
           font-family: 'Montserrat';
           font-size: 16px;
           cursor: pointer;
       }
       .titlebtnf a {   
        color: rgb(255, 255, 255);   
    }
    
       .titlebtns a {   
        color: rgb(0, 0, 0);   
    }
       .titlebtns a:active {   
        color: rgb(255, 255, 255);   
        background-color: rgb(0, 0, 0);
    }
    
       .titlebtnf:hover{
           background-color: rgba(255, 255, 255, 0.2);
       }
       .titlebtnf:active{
           
          color: black;
       }
       .titlebtns{
        border-radius: 7px;
        font-size: 16px;
        font-weight: 500;
        font-family: 'Montserrat';
        cursor: pointer;
       }
       .titlebtns:hover{
           background-color: rgba(255, 255, 255, 0.8)
       }
       .titlebtns:active{
           
          color: white;
       }
       .each-slide img{
        height: 70vh;
        width:  auto;
  
    }
    .slider{
        
        width:80vw;
    }

    .titlef{
        font-size: 30px;
        
        color: rgb(255, 255, 255);
      
        z-index: 2;
    }
    .titles{
        font-size: 30px;
        color: rgb(255, 255, 255);
        margin-top: -25px;
        z-index: 2;
    }
       
    
   img.background-img{
        text-align: center;
        filter: brightness(65%) saturate(150%);
        min-height: 100%;
        min-width: 30px;
        width: 90%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        text-align: center;
        
        top: 10%;
        left: 5%;  
        position: absolute;
        z-index: 1; 
    } 
    .hidemobile{
        display: flex;
    }
    .hidedekstop{
        display: none;
    }
    nav ul li a{
        color: rgb(0, 0, 0);
    }
    nav ul li{
        display: flex;
        flex-direction: row;
        justify-content:space-between;
    }
    nav ul{
        z-index: 2;
        width: 40%;
        right: 0; 
        position:  fixed;
        font-weight: 400;
        font-size: 18px;
        margin-top: .8%;
        margin-right: 5%;
        display: flex;
        justify-content: space-between;
    }
    .logolight{
        z-index: 2;
        margin: 0;
        position: fixed;
        margin-left: 5%;
    }
    header{
        background-color: white;
        width: 100%;
        padding: 0;
        padding: .8em 0;
        z-index: 2;
    }
    
    .container{
        padding: 0;
    }
    .arrowfader{
        color: white !important;
        
    }


     .aboutfade[data-scroll='in'] {
        opacity: 1;
        margin-top: -100px;
        transform: translateY(0px);
        transition: all 1.5s; 
        
    }
    .about-container{
       margin-bottom:-200px ;
    }
    
    .abouttext{
        width: 50vw;
        font-size: 17px;
    }
    .abouttextnumul{
        font-size: 17px;
        
    }
    .aboutsubtitle{
        
        text-align: center;
        margin: 0 auto;
    }
    .arrowfader{
        opacity: 1;
        color: white !important;
        position: absolute;
        top: 80%;
        z-index: 3;
        left: 46%;
        width: 4%;
    }
   
    .abouttextnum:first-child{
        background: url('../../images/one.svg') no-repeat  center center;
        background-size: 70px 60px;
      
    }
    .abouttextnum:nth-child(2){
        background: url('../../images/two.svg') no-repeat  bottom center;
        background-size: 70px 60px;
    }
    .abouttextnum:nth-child(3){
        background: url('../../images/three.svg') no-repeat  bottom center;
        background-size: 70px 60px;
    }
    .abouttextnum:nth-child(4){
        background: url('../../images/four.svg') no-repeat  bottom center;
        background-size: 70px 60px;
    }

    .abouttextnum{
        text-align: center;
        opacity: .6;
        padding: 20px 0;
        margin: 20px 0;
    }
    .aboutsection-second{
        border: none;
        width: 90%;
    }
    .aboutsection-second ul li{
        padding: 60px 0;
        color:white;
        border: none;
    }
    .aboutsection ul{
        background-color: #07254f;
        display: flex;
        width: 100%;
        flex-direction: row;
        border: none;
    }
    .aboutsection-second ul li:not(:nth-child(4)){
        border-right: 3px solid #ffffff;
    }
    .aboutsection-second ul li:not(:first-child){
        border-top: none;
    }
    .generalinfo-container h5,.generalinfo-container p {
        font-size: 17px;
        padding-top: 40px;
    }
    .generalinfo-container ul li img{
        width: 70px;
    }
    .generalinfo-container ul li:nth-child(2) img{
        width: 80px;
    }
    .generalinfo-container ul li:nth-child(4){
        padding-bottom:60px ;
    }
    .equipment-first-img{
        width: 600px;
    }
    .glider-contain{
        width: 50vw;
    }
    .yy img{
        width: 500px;
    }
    .yy:nth-child(3) img{
        width: 75vw;
    }
    
    .yy:nth-child(4) img{
        width:75vw;
    }
    
    table.blueTable{
        height: 550px;
        border: none;
        margin-bottom: 100px;
    }
    table.blueTable tbody td {
      font-size: 18px;
    }
    table.blueTable tbody td:nth-child(1) {
      font-size: 18px;
    }
    table.blueTable thead th {
      font-size: 18px;
    }
    .tablesign{
 color: black;
        padding: 0;
        font-size: 25px;
        text-align: center;
        margin: 0;
        margin-left: 36.5%;
        width: 35%;
    }
    .mediadekstop{
        margin-top: 100px;
        display: flex;
        flex-direction: row;
        text-align: center;
        padding: .8em 1.2em;
        
    }
    .mediadekstop p{
        width: 40vw;
    }
    .mediatext{
        padding: 0;
        font-size: 16px;
        opacity: 1;
    }
    iframe{
        width: 50vw;
        height: 35vh;
    }
    .mediatitle{
        font-size: 24px;
        width: 27%;
        margin: 0 auto;
        border-bottom: 3px solid #07254F;
        
    } .clients-container h1{
        font-size: 24px;
        width: 20vw;
        margin: 0 auto;
        border-bottom: 5px solid #EFF1F4;
    }
    .clients-container{
        padding-bottom:50px;
    }
    .clients-container div{
        display: inline;
        flex-direction: row;
        padding-bottom:50px;
    }
    
    .clients-container div:nth-child(2) img{
        width: 450px;
    }
    .clients-container div:nth-child(3) img{
        width: 350px;
        padding-bottom: 90px;
    }
    .clients-container div:nth-child(4) img{
        width: 200px;
        padding-bottom: 20px;
    }
    .clients-container div:nth-child(5) img{
        width: 300px;
        padding-bottom: 30px;
    }
    .clients-container div:nth-child(6) img{
        width: 300px;
        padding-bottom: 20px;
    }
    .clients-container div:nth-child(7) img{
        width:300px;
    }
    
    .clients-container div img{
        padding: 2.5em 2em;
        
    }
    
    .contact-container {
        height: 250px;
        margin: 0 auto;
        padding-top: 150px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        width: 60vw;
    }
    
    .contact-container img{
        width: 150px;
    }
    .firstcontactimg{
        width: 120px !important;
    }
    .emailtext{
        padding-top: 15px;
    }
    .innerfooter{
        width: 75%;
        margin: 0 auto;
        margin-left: 17.5%;
    }
    .formheader{
        padding-top:20px;
    }
    
    input[type=text], textarea{
        width: 30vw;
    }
    .footer-container div{
        flex-direction: row;
    }
   
    .footer-container div:nth-child(2) a {
        padding: 20px 30px 0 30px;
    } 
    .footer-container div:nth-child(2) a img{
        width: 50px;
    }

    .aboutsection-second ul li span{
        padding: 0 1vw;
    }

    
}
/*---------1350PX----------*/
@media only screen and (min-width:1350px){
    
     .formheader{
        font-size:30px;
    }
    
    .aboutfade[data-scroll='in'] {
        opacity: 1;
        margin-top: -170px;
        transform: translateY(0px);
        transition: all 1.5s; 
        
    }
    .generalinfo-container h5,.generalinfo-container p {
        font-size: 19px;
    }
    .equipment-first p{
        text-align: justify;
        font-size: 18px;
        line-height: 1.5em;
        opacity: .7;
        
        }
        .equipment-first li p{
            width: 50vw;
        }
        .tablesign{
 color: black;
            margin-left: 39.5%;
        }
        .mediatitle{
            padding-top: 0;
        }
        .mediatext{
            font-size: 18px;
            opacity: .7;
        }
        iframe{
            width: 50vw;
            height: 50vh;
        }
        .emailtext{
            font-size: 18px;
        }
        .contact-container span, .contact-container p{
            font-size: 18px;
        }
        label{
            font-size: 18px;
        }
        .formerror{
            font-size: 18px;
        }
}


/*--------1500px-------*/
@media only screen and (min-width:1500px){
    
    .aboutfade[data-scroll='in'] {
        opacity: 1;
        margin-top: -230px;
        transform: translateY(0px);
        transition: all 1.5s; 
        
    }
    .arrowfader{
        width: 4vw;
    }
    .abouttext,.abouttextnum{
        font-size: 18px;
    }
    .aboutsection-second ul li span{
        padding: 0 2vw;
        font-size: 18px;
    }
.papercandy h2{
    font-size: 30px;
}
table.blueTable{
    height: 500px;
    width: 50vw;
}
.papercandy img{
    width: 50vw;
}
.glider{
    margin-top: -150px;
}

}

@media only screen and (min-width:1700px){
   
    .contact-container{
        height:350px;
        padding-top:250px;
    }
    img.background-img{
        top:8%;
    }
    .aboutfade[data-scroll='in'] {
        opacity: 1;
        margin-top: -250px;
        transform: translateY(0px);
        transition: all 1.5s; 
        
    }
    iframe{
        width: 55vw;
        height: 55vh;
    }
    .titles, .titlef{
        right: 16%;
        font-size: 35px;
        line-height: 1.6em;
    }
    .titles{
        right: 12.5%;
    }
    .arrowfader{
        width: 4vw;
    }
    .aboutsection-second ul li span{
        padding: 0 3vw;
    }
    .glider-prev{
        left:3% ;
    }
    .glider-next{
        right:3% ;
    }

    #submit{
        padding: 15px 30px;
        font-size: 18px;
        font-weight: 500;
        transition: .4s all ease-in;
       
    }
   
}