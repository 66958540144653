@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300&display=swap');



.hidemobile{
    display: none;
}
.privacypolicy{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat',sans-serif;
    background-color: #07254f;
    color: white;
}
.privacypolicy ul{
    list-style-type: none;
    margin: 0;
    padding: 20px 100px 0 0;
}
/* img.background-img{
        text-align: center;
        filter: brightness(75%) saturate(140%);
        margin-top: -6em;
        min-height: 100%;
        min-width: 30px;
        width: 100%;
        height: auto;
        top: 0;
        left: 0;  
        position: absolute;
        z-index: -2; 
        
    } */

    /*--------HEADER--------*/

 

.privacypolicy p, span{
    font-size: .9em;
    line-height: 1.6em;
    padding: 0;
}

.privacypolicy .container{
    text-align: center;
    padding: .8em 1.2em;

}

.privacypolicy header{
    display: flex;
    justify-content: space-between;
    height: 70px;
    padding-bottom: 0;
    margin-top: -10px;

}

.logolight{
    padding-top: 10px;
    width: 45px;
    margin-left: -7px;

}

.main-title{
    margin-top: 193px;
    font-size: 12px;
    
}
.main-title h1{
    font-size: 22px;
    padding: 0;
}

.main-titlef{
    color: rgb(255, 255, 255); 
}
.arrowfader{
    width: 80px;
    text-align: center;
    margin: 0 auto;
    margin-top: 80px;
    opacity: .4;
}

.privacypolicy div:nth-child(2){
    color: white;
    padding: .8em 1.2em;
    font-weight: 500;
    opacity: .9;
    text-align: left;
}
.privacypolicy-content h1,h2{
    color: white;
    opacity: 1;
    text-decoration: none;
}